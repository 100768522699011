.sidebar {
    height: 100%;
    padding-top: $x3;
    background-color: $color-menu-bg-inside;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_opened {
        width: 240px; }

    &_closed {
        width: 50px; }

    &__title {
        display: flex;
        flex-direction: row; }

    &__icon-container {
        width: 20px;
        min-width: 20px; }

    &__delimiter {
        width: 10px;
        min-width: 10px; }

    &__text-container {
        color: #ffffff; }

    &__hide-container {
        color: #d9e1e8;
        text-size: 13px; }

    &__button {
        width: 100%;
        padding: $x3 $x4;
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: $color-gray-2;
        border-left: 4px solid inherit;

        &_toggle {
          font-size: 13px;
          line-height: 16px;
          font-weight: normal;
          color: $color-gray-2; }

        &:hover {
            cursor: pointer;
            background-color: $color-blue-4; }

        &_active {
            padding-left: 16px;
            cursor: pointer;
            background-color: $color-blue-4;
            border-left: 4px solid $color-blue-5; }

        &_closed {
            display: flex;
            justify-content: center; } } }
