@import "fonts";
@import "variables";
@import '../../../node_modules/@n3/kit/dist/n3kit.css';

* {
    margin: 0;
    padding: 0;
    outline: none;
    text-decoration: none;
    box-sizing: border-box;
    word-wrap: break-word; }

input[type="file"] {
    display: none; }

.app-container {
    width: 100%;
    margin-bottom: auto;

    &__selector {
        max-width: 280px;
        width: 280px; }

    &__flex {
        flex: 1; }

    &__break-word {
        word-wrap: break-word; }

    &__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_just-row {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap; }

        &_flex-start {
            width: 100%;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: flex-start; }

        &_flex-end {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end; }

        &_center {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center; }

        &_flex-height {
            flex: 1; } }

    &__column {
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &_auth {
            flex: 1;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 80px; }

        &_center {
            flex: 1;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }

        &_overflow {
            overflow-y: auto; } }

    &__align {
        &_top {
            align-items: flex-start; } }

    &__text {
        &_uppercase {
            text-transform: uppercase; } }


    &__font-size {
        &_12 {
            font-size: 12px; }

        &_13 {
            font-size: 13px; }

        &_14 {
            font-size: 14px; } }

    &__font-weight {
        &_300 {
            font-family: Roboto-Light, sans-serif; }

        &_500 {
            font-family: Roboto-Medium, sans-serif;
            font-weight: 500; } }

    &__color {
        &_blue-1 {
            color: $color-blue-1; }

        &_gray-2 {
            color: $color-gray-2; }

        &_gray-3 {
            color: $color-gray-3; }

        &_gray-4 {
            color: $color-gray-4; } }


    &__border {
        border: 1px solid $color-gray-3;

        &_top {
            border-top: 1px solid $color-gray-2; }

        &_bottom {
            border-bottom: 1px solid $color-gray-2; }

        &_left {
            border-left: 1px solid $color-gray-2; }

        &_right {
            border-right: 1px solid $color-gray-2; }

        &_none {
            border: none; } }

    &__bg {
        &_none {
            background-color: transparent;

            &:hover, &:focus, &:active {
                background-color: transparent; } } }

    &__cursor {
        &_pointer:hover {
            cursor: pointer; } } }

.staff-app {
    width: 100vw;
    height: 100vh;
    min-width: 1200px;
    font-family: Roboto-Regular, sans-serif;
    font-size: 15px; }

.table {
    font-size: 14px;

    &__column {
        &_width--5 {
            width: 5%; }

        &_width--10 {
            width: 10%; }

        &_width--12 {
            width: 12%; }

        &_width--15 {
            width: 15%; }

        &_width--20 {
            width: 20%; }

        &_width--25 {
            width: 25%; }

        &_width--30 {
            width: 30%; }

        &_width--35 {
            width: 35%; }

        &_width--40 {
            width: 40%; }

        &_width--45 {
            width: 45%; }

        &_width--50 {
            width: 50%; } }

    &__header {
        &_center {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none; } } }

@import "components/sidebar";
@import "components/user-pic";
@import "components/app_button";
@import "components/specialist_header";
@import "components/grid";
@import "components/auth";
@import "components/card";
