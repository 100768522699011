.user-pic {
    & button {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;

        &:hover, &:focus {
            border: none;
            background-color: inherit; } } }
