.specialist_header {

  &__photo {
    width: 400px;
    height: 250px;
    margin: 15px auto auto;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &_edit {
      width: 350px;
      height: 200px;
      display: flex; } }

  &__column {
    float: left;
    padding-right: 40px;
    min-width: 200px;
    max-width: 260px;
    word-wrap: break-word;

    input {
      max-width: 260px; } } }
