.grid {
  display: grid;
  grid-column-gap: 30px;
  word-wrap: break-word;
  max-width: 100%;

  &__2 {
    grid-template-columns: repeat(2, calc(50% - 15px));

    &_no-gap {
      grid-template-columns: repeat(2, 50%);
      grid-gap: 0px 0px; }

    &_gap-row {
      grid-row-gap: 20px; }

    &_gap-row-align-end {
      align-self: end; } }

  &__3 {
    grid-template-columns: repeat(3, calc(33.33% - 20px));

    &_no-gap {
      grid-template-columns: repeat(2, 33.33%);
      grid-gap: 0px 0px; }

    &_gap-row {
      grid-row-gap: 20px; } } }
