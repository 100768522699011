.auth {
  &__main-container {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    width: 370px;
    padding: 30px 50px;
    background-color: $color-white; }

  &__header {
    font-family: Roboto-Medium, sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: $color-gray-4;
    text-align: center; }

  &__password-reset-msg {
    font-family: Roboto-Regular, sans-serif;
    font-size: 14px;
    text-align: center;
    color: $color-gray-4;

    &_highlighted {
      font-family: Roboto, sans-serif;
      color: $color-black;
      font-weight: bold; } } }
