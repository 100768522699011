.app-button {
    padding: 7px 15px;
    border: 1px solid $color-gray-2;
    color: $color-blue-1;
    border-radius: 2px;
    font-family: Roboto-Medium, sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;

    &_small {
        font-size: 13px;
        font-weight: 500;
        text-transform: none;
        padding-top: 7px;
        padding-bottom: 7px; }

    &:hover, &:active {
        background-color: $color-blue-2;
        border: 1px solid $color-blue-2;
        color: white; }

    &__delete-button {
        color: #d64c4c;

        &:hover, &:active {
            background-color: #d64c4c;
            border: 1px solid #d64c4c;
            color: white; } } }
