$x1: 5px;
$x2: 10px;
$x3: 15px;
$x4: 20px;
$x5: 25px;
$x6: 30px;

$grid-c: 72px;
$grid-s: 26px;

$color-gray-1: #F5F7F8;
$color-gray-2: #D0E1E8;
$color-gray-3: #78909C;
$color-gray-4: #4D5F71;

$color-red: #D64C4C;
$color-green: #62B862;
$color-white: #FFFFFF;
$color-black: #1D1D1D;

$color-blue-1: #4375CD;
$color-blue-2: #5A89D9;
$color-blue-3: #2F5BA9;
$color-blue-4: #688BB0;
$color-blue-5: #3765A4;

$color-menu-bg: #99B2BE;
$color-menu-bg-inside: #88A7B6;
$color-menu-bg-active: #9BB2D1;

